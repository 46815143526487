<template>
	<div class="add-details">
		<div class="u-img">
			<div>
				<van-uploader
					v-model="form.fileList"
					upload-icon="plus"
					:before-read="beforeRead"
					:after-read="afterRead"
					:max-size="10 * 1024 * 1024"
					@oversize="onOversize"
					:capture="camera"
				/>
				<!-- <div v-if="form.fileList.length">
          <img :src="form.fileList" alt="" />
        </div> -->
			</div>
			<div>(上传照片)</div>
		</div>
		<div class="info-block">
			<van-row class="i-title" type="flex" align="center" justify="space-between">
				<van-col>
					<span class="info-block-title">员工信息</span>
				</van-col>
			</van-row>
			<van-field v-model="form.name" required label="访姓名" placeholder="填写姓名" />
			<van-field required label="性别">
				<template #input>
					<van-radio-group v-model="form.sex" direction="horizontal">
						<van-radio name="0" :checked-color="defineStyle.themeColor">男</van-radio>
						<van-radio name="1" :checked-color="defineStyle.themeColor">女</van-radio>
					</van-radio-group>
				</template>
			</van-field>
			<van-field
				v-model="form.age"
				label="年龄"
				required
				right-icon="arrow"
				readonly
				placeholder="填写年龄"
				@click="showAge = true"
			/>
			<van-field v-model="form.titles" label="职称" required placeholder="填写职称" />
			<van-field
				v-model="form.department"
				label="部门"
				required
				right-icon="arrow"
				readonly
				@click="showDepartment = true"
				placeholder="填写部门"
			/>
			<van-field
				v-model="form.enterTime"
				label="入职时间"
				required
				right-icon="arrow"
				readonly
				@click="showEnterTime = true"
				placeholder="填写入职时间"
			/>
			<van-field label="门禁权限" required placeholder="填写门禁权限">
				<template #input>
					<van-switch
						:active-color="defineStyle.themeColor"
						:inactive-color="defineStyle.color.gray"
						v-model="form.jurisdiction"
						size="24"
					/>
				</template>
			</van-field>
			<van-field v-show="form.jurisdiction"
				v-model="TerminalName"
				label="门禁终端"
				required
				right-icon="arrow"
				readonly
				@click="showTerminal = true"
				placeholder="填写门禁终端"
			/>
		</div>
		<div class="a-btn">
			<van-button
				type="primary"
				:disabled="isEmpty"
				@click="showDelete = true"
				:color="defineStyle.color.red"
				>删除</van-button
			>
			<van-button
				type="primary"
				:disabled="isEmpty"
				:color="defineStyle.themeColor"
				@click="Update"
				>确认</van-button
			>
			<van-button @click="$router.go(-1)" type="primary" :color="defineStyle.color.gray"
				>取消</van-button
			>
		</div>
		<van-popup v-model="showDepartment" position="bottom">
			<van-picker
				show-toolbar
				:columns="columns"
				@confirm="onConfirmDepartment"
				@cancel="showDepartment = false"
			/>
		</van-popup>
		<van-popup v-model="showTerminal" position="bottom">
			<van-picker
				show-toolbar
				:columns="columnsTerminal"
				@confirm="onConfirmTerminal"
				@cancel="showTerminal = false"
			/>
		</van-popup>
		<van-popup v-model="showAge" position="bottom">
			<van-datetime-picker
				type="date"
				v-model="chooseTime"
				title="选择年月日"
				:min-date="minDate"
				:max-date="maxDate"
				@confirm="onConfirmTime"
				@cancel="showAge = false"
			/>
		</van-popup>
		<van-popup v-model="showEnterTime" position="bottom">
			<van-datetime-picker
				type="date"
				v-model="enterTime"
				title="选择年月日"
				:min-date="minDate"
				:max-date="maxDate"
				@confirm="ConfirmEnterTime"
				@cancel="showEnterTime = false"
			/>
		</van-popup>
		<van-action-sheet
			v-model="showDelete"
			:actions="actions"
			cancel-text="取消"
			@select="deleteInfo"
			close-on-click-action
			@cancel="showDelete = false"
		/>
	</div>
</template>
<script>
import { UploadPicture } from '@/api/Upload.js'
import { GetById, Del, AddOrUpdate,GetTerminalsByType } from '@/api/PeopleManagement/people.js'
import {
	Field,
	RadioGroup,
	Radio,
	Button,
	Uploader,
	DatetimePicker,
	Picker,
	Popup,
	Switch,
	ActionSheet,
	Toast,
} from 'vant'
export default {
	components: {
		[Field.name]: Field,
		[RadioGroup.name]: RadioGroup,
		[Radio.name]: Radio,
		[Button.name]: Button,
		[Uploader.name]: Uploader,
		[Popup.name]: Popup,
		[DatetimePicker.name]: DatetimePicker,
		[Picker.name]: Picker,
		[Switch.name]: Switch,
		[Toast.name]: Toast,
		[ActionSheet.name]: ActionSheet,
	},
	data() {
		return {
			form: {
				fileList: [
					{
						url: '',
					},
				],
				name: '',
				sex: '1',
				age: null,
				titles: '',
				department: '',
				departmentId: 0,
				enterTime: '',
				jurisdiction: false,
				id: 0,
				userPhoto: '',
				TerminalCode: null,
				DHUserId: null,
			},
			minDate: new Date(1940, 0, 1),
			maxDate: new Date(),
			showDepartment: false,
			showAge: false,
			showEnterTime: false,
			showDelete: false,
			chooseTime: new Date(),
			enterTime: new Date(),
			columns: JSON.parse(this.defineMethods.getSessionStorage('Department')),
			actions: [{ name: '删除', color: this.defineStyle.color.red }],
			addUserCommand: null,
			addUserFaceCommand: null,
			delUserCommand: null,
			delUserFaceCommand: null,
			// DoorUserId: this.defineMethods.genID(),
			handleStatus: 5, // 处理模式 1 只 删除不做处理   19先删除后添加
			showTerminal:false,
			TerminalName:'',
		    columnsTerminal:[],
			TerminalCode:null,
			IsAccess:false,
			camera:"camera"
		}
	},
	computed: {
		isEmpty() {
			return Object.values(this.form).includes('')
		},
	},
	methods: {
		async GetTerminals(){
			let that=this;
            let result = await GetTerminalsByType(4)
			if (result.ResultCode === 200) {
                result.Data.forEach((item,i) => {
					let {TerminalCode,NickName}=item;
					that.columnsTerminal.push({id:TerminalCode,text:NickName});
					if(item.TerminalCode==that.form.TerminalCode){
						that.TerminalName=item.NickName;
					}
				});
				console.log(this.columnsTerminal)
			}
		},
		//图片上传之前
		beforeRead(file) {
			if (file.type !== 'image/jpeg' && file.type != 'image/png') {
				Toast('请上传 jpg,png 格式图片')
				return false
			}
			return true
		},
		//文件上传完
		async afterRead(file) {
			file.status = 'uploading'
			file.message = '上传中...'
			//创建文件对象
			var forms = new FormData()
			//追加文件对象
			forms.append('file', file.file)
			//上传文件
			var result = await UploadPicture(forms)
			if (result.code === 200) {
				file.status = 'success'
				file.message = '上传成功'
				this.form.userPhoto = result.Data
			} else {
				file.status = 'failed'
				file.message = '上传失败' + result.msg
			}
		},
		onOversize(file) {
			console.log(file)
			Toast('文件大小不能超过 10mb')
		},
		//修改人员
		Update() {
			debugger
			if(this.form.jurisdiction){//现在门禁为true
				//判断门禁终端是否变化了
				 if(this.TerminalCode!=this.form.TerminalCode){
					 if(this.IsAccess){
                        //删除之前的
				    	this.deleteUserJson2(this.delUserCommand.CommandJson);//
					}else{
						 this.addOrUpdateJosn(this.addUserCommand.CommandJson)
					}
					
				 }
				 else{//没变化
					this.addOrUpdateJosn(this.addUserCommand.CommandJson)
				 }

			}else{//现在门禁为false
				if(this.IsAccess==true){//之前为true 删除之前的
					this.deleteUserJson(this.delUserCommand.CommandJson);//
				}
				this.Update2();
			}
		},
		async Update2() {
			console.log(this.form)
			let model = {
				Id: this.form.id,
				Name: this.form.name,
				Sex: Number(this.form.sex),
				Age: this.form.age,
				Position: this.form.titles,
				DepartmentId: this.form.departmentId,
				AccessPermissions: this.form.jurisdiction,
				HireDate: this.dayjs(this.form.enterTime).unix(),
				UserPhoto: this.form.userPhoto,
				TerminalCode: this.TerminalCode,
				DHUserId: this.form.DHUserId,
			}
			let result = await AddOrUpdate(model)
			if (result.ResultCode === 200) {
				//成功
				Toast.success('添加成功')
				this.$router.go(-1)
			} else {
				Toast.fail('添加失败')
			}
		},
		//删除
		async deleteInfo() {
			this.handleStatus = 10
			// this.deleteUserJson(this.delUserCommand.CommandJson);
			let jsonData = this.delUserCommand.CommandJson
			jsonData = jsonData.replace('#ServersCode', '' + this.form.TerminalCode + '')
			jsonData = jsonData.replace('#mode', 'byEmployeeNo')
			jsonData = jsonData.replace('#employeeNo', '' + this.form.DHUserId + '')
			console.log(jsonData)
			let result = this.handdleMsg(jsonData) //添加人员的命令
			console.log(result) //
		},
		async deleteInfo2() {
			let result = await Del(this.form.id)
			if (result.ResultCode === 200) {
				Toast.success('已删除')
				this.$router.go(-1)
			} else {
				Toast.fail('删除失败')
			}
		},
		//替换命令的json格式
		addOrUpdateJosn(jsonData) {
			this.handleStatus=3;
			let a = this.form.enterTime.replace('/', '-')
			a = a.replace('/', '-')
			jsonData = jsonData.replace('#ServersCode', '' + this.TerminalCode + '')
			jsonData = jsonData.replace('#employeeNo', '' + this.form.DHUserId + '')
			jsonData = jsonData.replace('#name', '' + this.form.name + '')
			jsonData = jsonData.replace('#userType', 'normal')
			jsonData = jsonData.replace('#enable', 'true')
			jsonData = jsonData.replace('#beginTime', '' + a + 'T00:00:01')
			jsonData = jsonData.replace('#endTime', '2037-01-01T23:59:59')
			jsonData = jsonData.replace('#timeType', 'local')
			jsonData = jsonData.replace('#doorRight', '1')
			jsonData = jsonData.replace('#doorNo', '1')
			jsonData = jsonData.replace('#planTemplateNo', '1')
			jsonData = jsonData.replace('#userVerifyMode', 'face')
			console.log(jsonData)
			let result = this.handdleMsg(jsonData) //添加人员的命令
			console.log(result) //
		},
		deleteUserJson(jsonData) {
			this.handleStatus=1;//只删除
			//{ "ServersCode":"#ServersCode","Msg":  {"UserInfoDetail": {"mode": "#mode","EmployeeNoList": [{"employeeNo": "#employeeNo"}]}},"Command":15 }
			jsonData = jsonData.replace('#ServersCode', '' + this.form.TerminalCode + '')
			jsonData = jsonData.replace('#mode', 'byEmployeeNo')
			jsonData = jsonData.replace('#employeeNo', '' + this.form.DHUserId + '')
			console.log(jsonData)
			let result = this.handdleMsg(jsonData) //添加人员的命令
			console.log(result) //
		},
		deleteUserJson2(jsonData) {
			this.handleStatus=19;//只删除
			//{ "ServersCode":"#ServersCode","Msg":  {"UserInfoDetail": {"mode": "#mode","EmployeeNoList": [{"employeeNo": "#employeeNo"}]}},"Command":15 }
			jsonData = jsonData.replace('#ServersCode', '' + this.form.TerminalCode + '')
			jsonData = jsonData.replace('#mode', 'byEmployeeNo')
			jsonData = jsonData.replace('#employeeNo', '' + this.form.DHUserId + '')
			console.log(jsonData)
			let result = this.handdleMsg(jsonData) //添加人员的命令
			console.log(result) //
		},
		deleteFaceJson(jsonData) {
			// { "ServersCode":"#ServersCode","Msg":   {"faceLibType": "#faceLibType","FDID": "#FDID","FPID": "#FPID","deleteFP":#deleteFP},"Command":25 }
			jsonData = jsonData.replace('#ServersCode', '' + this.TerminalCode + '')
			jsonData = jsonData.replace('#faceLibType', 'blackFD')
			jsonData = jsonData.replace('#FDID', '1')
			jsonData = jsonData.replace('#FPID', '' + this.form.DHUserId + '')
			jsonData = jsonData.replace('#deleteFP', 'true')
			console.log(jsonData)
			return jsonData
		},
		onConfirmTerminal(val) {
			this.TerminalCode = val.id
			this.TerminalName = val.text
			this.showTerminal = false
		},
		//websocket消息
		handdleMsg(msg) {
			let that = this
			console.log(that.global.ws)
			let wss=null;
			that.global.ws.forEach((item,i)=>{
				if (item.name == "door")
                { wss=item.info;}
			});
			if (wss && wss.readyState == 1) {
				console.log('发送信息', msg)
				wss.send(msg)
			}
			wss.onmessage = function (res) {
				debugger
				console.log('收到服务器内容', res.data)
				console.log(JSON.parse(res.data).Msg)
				let result = JSON.parse(res.data).Msg
                //处理哪一步
				 if(JSON.parse(result).Code==500){
					Toast.fail("图片有问题请重新选择图片");
					return;
				}
				if(JSON.parse(result).Code == 200 ){
				if(that.handleStatus==19){
					that.addOrUpdateJosn(that.addUserCommand.CommandJson);
					return;
				}
				if(that.handleStatus==1)//只 删除
                    {that.Update2();}
					if(that.handleStatus==4){
						that.Update2();//调取api接口
					}
					if(that.handleStatus==10){//删除成功
                        that.deleteInfo2();
					}
					if(that.handleStatus==3){
						that.handleStatus=4;
                    	let b = that.addUserFaceCommand.CommandJson
						b = b.replace('#ServersCode', '' + that.TerminalCode + '')
						b = b.replace('#faceLibType', 'blackFD')
						b = b.replace('#FDID', '1')
						b = b.replace('#FPID', '' + that.form.DHUserId + '')
						b = b.replace('#ImageUrl', '' + that.form.userPhoto + '')
						that.handdleMsg(b) //添加人员的命令
					}
					
				}
				// if (
				// 	JSON.parse(result).Code == 200 &&
				// 	JSON.parse(result).Data.Msg == 'Set User Success'
				// ) {
				// 	// if(that.form.)
				// 	let b = that.addUserFaceCommand.CommandJson
				// 	b = b.replace('#ServersCode', '' + that.form.TerminalCode + '')
				// 	b = b.replace('#faceLibType', 'blackFD')
				// 	b = b.replace('#FDID', '1')
				// 	b = b.replace('#FPID', '' + that.form.DHUserId + '')
				// 	b = b.replace('#ImageUrl', '' + that.form.userPhoto + '')
				// 	that.handdleMsg(b) //添加人员的命令
				// }
				// if (
				// 	JSON.parse(result).Code == 200 &&
				// 	JSON.parse(result).Data.Msg == 'Set Face Success' &&
				// 	that.handleStatus == 5
				// ) {
				// 	that.handleStatus = 10
				// 	that.Update2()
				// 	return
				// }
				// if (
				// 	JSON.parse(result).Code == 200 &&
				// 	JSON.parse(result).Data.Msg == 'Set Face Success' &&
				// 	that.handleStatus == 10
				// ) {
				// 	that.handleStatus = 15
				// 	that.deleteUserJson(that.delUserCommand.CommandJson)
				// 	return
				// }
				// // JSON.parse(result).Code == 200 &&
				// // JSON.parse(result).Data.Msg == "Del User Success";
				// if (that.handleStatus == 15) {
				// 	that.deleteInfo2()
				// 	return
				// }
			}
		},
		onConfirmDepartment(val) {
			this.form.departmentId = val.id
			this.form.department = val.text
			this.showDepartment = false
		},
		onConfirmTime(val) {
			this.form.age = this.dayjs().format('YYYY') - this.dayjs(val).format('YYYY')
			this.showAge = false
		},
		ConfirmEnterTime(val) {
			this.form.enterTime = this.dayjs(val).format('YYYY/MM/DD')
			this.showEnterTime = false
		},
	},
	async mounted() {
		
		JSON.parse(this.defineMethods.getSessionStorage('TerminalCommandList')).forEach(
			(item, index) => {
				if (item.CommandType == 5 && item.TerminalType == 4) this.addUserCommand = item
				if (item.CommandType == 7 && item.TerminalType == 4) this.addUserFaceCommand = item
				if (item.CommandType == 6 && item.TerminalType == 4) this.delUserCommand = item
				if (item.CommandType == 8 && item.TerminalType == 4) this.delUserFaceCommand = item
			}
		)
		console.log(this.addUserCommand)
		console.log(this.addUserFaceCommand)
		console.log(this.delUserCommand)
		console.log(this.delUserFaceCommand)
		console.log(this.$route.query)
		if (!this.defineMethods.isNullOrEmpty(this.$route.query.id)) {
			this.form.id = this.$route.query.id
			let result = await GetById(this.$route.query.id)
			console.log(result)
			if (result.ResultCode === 200) {
				this.form.fileList[0].url = result.Data.UserPhoto
				this.form.userPhoto = result.Data.UserPhoto
				this.form.name = result.Data.Name
				this.form.sex = result.Data.Sex.toString()
				this.form.age = result.Data.Age
				this.form.titles = result.Data.Position
				this.form.department = result.Data.DepartmentName
				this.form.departmentId = result.Data.DepartmentId
				this.form.DHUserId = result.Data.DHUserId
				this.form.TerminalCode = result.Data.TerminalCode
				this.form.enterTime = this.dayjs.unix(result.Data.CreationTime).format('YYYY/MM/DD')
				this.form.jurisdiction = result.Data.AccessPermissions
				this.IsAccess=result.Data.AccessPermissions
				this.TerminalCode=result.Data.TerminalCode
				this.GetTerminals();
			}
			
		}
	},
}
</script>
<style lang="scss" scoped>
@import '../../../style/public.scss';
.add-details {
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	.a-btn {
		margin: 0 16px;
		margin-top: 24px;
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		> button {
			margin-right: 11px;
		}
	}
	.u-img {
		background: #fff;
		padding: 24px 0;
		margin: 0 auto;
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		align-items: center;
		font-size: 14px;
		font-weight: 400;
		color: $defocus;
		text-align: center;
		margin-bottom: 5px;
		> div:first-child {
			width: 107px;
			height: 107px;
			border-radius: 8px;
			background: $bgColor;
			border: 1px dashed $gray;
			display: flex;
			flex-flow: column nowrap;
			justify-content: center;
			align-items: center;
			overflow: hidden;
		}
		> div:last-child {
			margin-top: 8px;
		}
	}
	.info-block {
		min-height: 44px;
		box-sizing: border-box;
		position: relative;
		margin-bottom: 4px;
		background: #fff;
		padding-top: 1px;

		> .i-title {
			padding: 0 16px;
			box-sizing: border-box;
		}
		&-title {
			position: relative;
			padding-left: 10px;
			line-height: 44px;
			font-size: 16px;
			font-weight: 400;
			color: $titleColor;
		}
		&-title:before {
			content: '';
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 0;
			height: 18px;
			width: 4px;
			background: $themeColor;
			border-radius: #{'4PX'};
		}
		.slot-icon {
			color: $themeColor;
			height: 52px;
			line-height: 52px;
		}
	}
	.info-block:before {
		content: '';
		position: absolute;
		box-sizing: border-box;
		pointer-events: none;
		right: 0;
		bottom: 0;
		left: 0;
		border-bottom: #{'1PX'} solid #ebedf0;
		transform: scaleY(0.5);
	}
}
</style>
<style lang="scss">
.add-details {
	.u-img {
		.van-uploader + div {
			display: none;
		}
		.van-uploader__preview + .van-uploader__upload {
			display: none;
		}
		.van-uploader__preview {
			margin: 0;
		}
		.van-uploader__preview-image {
			width: 107px;
			height: 107px;
			display: flex;
			flex-flow: row nowrap;
			justify-content: center;
			align-items: center;
		}
		.van-uploader__upload {
			margin: 0;
			background-color: transparent;
		}
		.van-uploader__upload-icon {
			color: #a0a0a0;
			font-size: 48px;
		}
	}
}
</style>